import React, { useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import Loading from '../components/Loading'
import { Button } from '@material-tailwind/react'
import { endpoint } from '../utils/authorizeCard'
import { Card, CardContent, Stack, Typography, Box, LinearProgress } from '@mui/material'
import { ElectricBolt as BoltIcon, Speed as SpeedIcon } from '@mui/icons-material'

const StartChargingComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [selectedAmperage, setSelectedAmperage] = useState(32)  // Default to Fast charging (32A)
  
  const {
    getAccessTokenSilently,
    logout
  } = useAuth0()

  const startCharging = async () => {
    setIsLoading(true)
    setError(null)
    
    try {
      let token
      try {
        token = await getAccessTokenSilently()
      } catch (_) {
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
      
      const response = await fetch(`${endpoint}/charging/start`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amperage: selectedAmperage }),
      })

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.message || 'Failed to start charging')
      }

      setSuccess(true)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="bg-light-gray min-h-screen">
      <div className="flex flex-col items-center px-4 py-6">
        <Card sx={{ minWidth: 275, width: '100%', maxWidth: '400px', mb: 2 }}>
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="center" mb={3}>
              <BoltIcon color="primary" sx={{ fontSize: 40 }} />
              <Typography variant="h5" component="div">
                Charging Settings
              </Typography>
            </Stack>

            {error && (
              <Box sx={{ mb: 3 }}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Box>
            )}

            {success ? (
              <Box sx={{ textAlign: 'center', py: 3 }}>
                <Typography variant="h6" color="success.main" gutterBottom>
                  Charging Started Successfully!
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your vehicle will begin charging momentarily
                </Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ mb: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Select Charging Speed
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="filled"
                      className={`flex-1 ${
                        selectedAmperage === 32 
                          ? 'bg-teal-700 ring-4 ring-teal-200 shadow-lg scale-105 transform transition-all duration-200' 
                          : 'bg-teal-500 hover:bg-teal-600 transition-all duration-200'
                      } flex items-center justify-center gap-2`}
                      onClick={() => setSelectedAmperage(32)}
                    >
                      <BoltIcon className={`h-5 w-5 ${selectedAmperage === 32 ? 'text-yellow-300' : ''}`} />
                      <div className={selectedAmperage === 32 ? 'text-yellow-300' : ''}>
                        <div className="text-sm font-bold">Fast</div>
                        <div className="text-xs opacity-80">32A</div>
                      </div>
                    </Button>
                    <Button
                      variant="filled"
                      className={`flex-1 ${
                        selectedAmperage === 20 
                          ? 'bg-teal-700 ring-4 ring-teal-200 shadow-lg scale-105 transform transition-all duration-200' 
                          : 'bg-teal-500 hover:bg-teal-600 transition-all duration-200'
                      } flex items-center justify-center gap-2`}
                      onClick={() => setSelectedAmperage(20)}
                    >
                      <SpeedIcon className={`h-5 w-5 ${selectedAmperage === 20 ? 'text-yellow-300' : ''}`} />
                      <div className={selectedAmperage === 20 ? 'text-yellow-300' : ''}>
                        <div className="text-sm font-bold">Normal</div>
                        <div className="text-xs opacity-80">20A</div>
                      </div>
                    </Button>
                  </Stack>
                </Box>

                <Box sx={{ position: 'relative' }}>
                  <Button
                    className="w-full bg-teal-500 hover:bg-teal-600 text-white font-bold py-3 px-4 rounded-lg"
                    onClick={startCharging}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center gap-3">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                        <span>Starting Charging...</span>
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-2">
                        <BoltIcon />
                        <span>Start Charging</span>
                      </div>
                    )}
                  </Button>
                  {isLoading && (
                    <LinearProgress 
                      sx={{ 
                        position: 'absolute', 
                        bottom: 0, 
                        left: 0, 
                        right: 0,
                        borderRadius: '0 0 8px 8px'
                      }} 
                    />
                  )}
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default withAuthenticationRequired(StartChargingComponent, {
  onRedirecting: () => <Loading />
})

import { useAuth0 } from "@auth0/auth0-react"
import React, { useState } from 'react'
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Button,
  Typography,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  FormHelperText,
  Container,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { Delete as DeleteIcon, CheckCircle as CheckIcon, Cancel as CrossIcon, Help as UnknownIcon, Home as PrivateIcon, Store as PublicIcon, Bolt as TraditionalIcon, EnergySavingsLeaf as EcoIcon, SolarPower as FullGreenIcon } from '@mui/icons-material'
import { createChargepoint } from "../utils/userRequests"
import { useHistory } from "react-router-dom"

const MIN_AMPERAGE = 6
const MAX_AMPERAGE = 100

const RegisterChargePoint = () => {
  const { getAccessTokenSilently } = useAuth0()
  const history = useHistory()

  console.log("In register charge point")
  const [formData, setFormData] = useState({
    name: '',
    chargepointId: '',
    password: '',
    confirmPassword: '',
    isPublic: false,
    ocppUrl: '',
    ocppPassword: '',
    mode: 'traditional',
    autoStart: false,
    newUserEmail: '',
    authorizedUsers: [],
    maxAmperage: 32
  })

  const [nameError, setNameError] = useState('')
  const [chargepointIdError, setChargepointIdError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [ocppUrlError, setOcppUrlError] = useState('')

  // Mock data for non-editable fields
  const subscriptionStatus = {
    active: true,
    expirationDate: '2024-12-31'
  }
  let chargerSupport = 'unknown'

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    
    if (name === 'maxAmperage') {
      const validatedValue = validateMaxAmperage(value)
      setFormData(prev => ({
        ...prev,
        maxAmperage: validatedValue
      }))
      return
    }

    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))

    // Run appropriate validation based on field
    switch (name) {
      case 'password':
      case 'confirmPassword':
        validatePasswords(
          name === 'password' ? value : formData.password,
          name === 'confirmPassword' ? value : formData.confirmPassword
        )
        break
      case 'name':
        validateName(value)
        break
      case 'chargepointId':
        validateChargepointId(value)
        break
      case 'newUserEmail':
        validateEmail(value)
        break
      case 'ocppUrl':
        validateOcppUrl(value)
        break
      default:
        break
    }
  }

  const validateName = (value) => {
    if (!value.trim()) {
      setNameError('Name is required')
      return false
    }
    setNameError('')
    return true
  }

  const validateChargepointId = (value) => {
    if (!value.trim()) {
      setChargepointIdError('Chargepoint ID is required')
      return false
    }
    setChargepointIdError('')
    return true
  }

  const validatePasswords = (password, confirmPassword) => {
    if (!password) {
      setPasswordError('Password is required')
      return false
    }
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match')
      return false
    }
    setPasswordError('')
    return true
  }

  const validateMaxAmperage = (value) => {
    const numValue = parseInt(value)
    if (isNaN(numValue) || numValue < MIN_AMPERAGE || numValue > MAX_AMPERAGE) {
      return formData.maxAmperage
    }
    return numValue
  }

  const validateEmail = (email) => {
    if (!email) {
      setEmailError('Email is required')
      return false
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format')
      return false
    }
    setEmailError('')
    return true
  }

  const validateOcppUrl = (url) => {
    if (formData.isPublic) {
      if (!url) {
        setOcppUrlError('OCPP URL is required for public chargers')
        return false
      }
      
      try {
        const urlObj = new URL(url)
        if (!['ws:', 'wss:'].includes(urlObj.protocol)) {
          setOcppUrlError('OCPP URL must start with ws:// or wss://')
          return false
        }
      } catch (e) {
        setOcppUrlError('Please enter a valid URL')
        return false
      }
    }
    setOcppUrlError('')
    return true
  }

  const handleAddUser = () => {
    if (!formData.newUserEmail) {
      setEmailError('Email is required')
      return
    }
    if (!formData.newUserEmail.includes('@')) {
      setEmailError('Invalid email format')
      return
    }
    setFormData(prev => ({
      ...prev,
      authorizedUsers: [...prev.authorizedUsers, { email: formData.newUserEmail }],
      newUserEmail: ''
    }))
    setEmailError('')
  }

  const handleRemoveUser = (email) => {
    setFormData(prev => ({
      ...prev,
      authorizedUsers: prev.authorizedUsers.filter(user => user.email !== email)
    }))
  }

  const getSupportIcon = () => {
    switch (chargerSupport) {
      case 'yes':
        return <CheckIcon color="success" />
      case 'no':
        return <CrossIcon color="error" />
      default:
        return <UnknownIcon color="disabled" />
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (passwordError) return

    // Validate all fields before submission
    const isNameValid = validateName(formData.name)
    const isChargepointIdValid = validateChargepointId(formData.chargepointId)
    const isPasswordValid = validatePasswords(formData.password, formData.confirmPassword)
    const isOcppUrlValid = validateOcppUrl(formData.ocppUrl)

    if (!isNameValid || !isChargepointIdValid || !isPasswordValid || 
        (formData.isPublic && !isOcppUrlValid)) {
      return
    }

    try {
      const token = await getAccessTokenSilently()
      const result = await createChargepoint(token, formData)
      
      if (result.success) {
        history.push('/home')
      } else {
        // You might want to add state for API errors and display them to the user
        console.error('Failed to register chargepoint:', result.reason)
      }
    } catch (error) {
      console.error('Error registering chargepoint:', error)
    }
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Register Charge Point
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            required
            label="Charger Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            margin="normal"
            error={!!nameError}
            helperText={nameError}
            placeholder="e.g. Home Garage Charger"
          />

          <TextField
            fullWidth
            required
            label="Chargepoint ID"
            name="chargepointId"
            value={formData.chargepointId}
            onChange={handleInputChange}
            margin="normal"
            error={!!chargepointIdError}
            helperText={chargepointIdError}
          />

          <TextField
            fullWidth
            required
            type="number"
            label="Maximum Amperage"
            name="maxAmperage"
            value={formData.maxAmperage}
            onChange={handleInputChange}
            margin="normal"
            inputProps={{ 
              min: MIN_AMPERAGE,
              max: MAX_AMPERAGE,
              step: 1
            }}
            helperText={`Specify the maximum amperage (${MIN_AMPERAGE}A - ${MAX_AMPERAGE}A)`}
          />

          <TextField
            fullWidth
            required
            type="password"
            label="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            margin="normal"
            error={!!passwordError}
          />

          <TextField
            fullWidth
            required
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            margin="normal"
            error={!!passwordError}
            helperText={passwordError}
          />

          <FormControl component="fieldset" margin="normal" fullWidth>
            <Typography variant="subtitle1">Is your charger private or public?</Typography>
            <ToggleButtonGroup
              exclusive
              value={formData.isPublic}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setFormData(prev => ({ ...prev, isPublic: newValue }))
                }
              }}
              sx={{ mt: 1 }}
            >
              <ToggleButton value={false}>
                <PrivateIcon 
                  sx={{ 
                    mr: 1,
                    color: formData.isPublic === false ? 'primary.main' : 'inherit'
                  }} 
                />
                Private
              </ToggleButton>
              <ToggleButton value={true}>
                <PublicIcon 
                  sx={{ 
                    mr: 1,
                    color: formData.isPublic === true ? 'primary.main' : 'inherit'
                  }} 
                />
                Public
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          {formData.isPublic && (
            <>
              <TextField
                fullWidth
                required
                label="OCPP URL"
                name="ocppUrl"
                value={formData.ocppUrl}
                onChange={handleInputChange}
                margin="normal"
                error={!!ocppUrlError}
                helperText={ocppUrlError}
                placeholder="ws://example.com or wss://example.com"
              />
              <TextField
                fullWidth
                required
                label="OCPP Password"
                name="ocppPassword"
                value={formData.ocppPassword}
                onChange={handleInputChange}
                margin="normal"
              />
            </>
          )}

          <FormControl component="fieldset" margin="normal" fullWidth>
            <Typography variant="subtitle1">Mode</Typography>
            <ToggleButtonGroup
              exclusive
              value={formData.mode}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setFormData(prev => ({ ...prev, mode: newValue }))
                }
              }}
              sx={{ mt: 1 }}
            >
              <ToggleButton value="traditional">
                <TraditionalIcon 
                  sx={{ 
                    mr: 1,
                    color: formData.mode === 'traditional' ? 'primary.main' : 'inherit'
                  }} 
                />
                Traditional
              </ToggleButton>
              <ToggleButton value="eco">
                <EcoIcon 
                  sx={{ 
                    mr: 1,
                    color: formData.mode === 'eco' ? 'primary.main' : 'inherit'
                  }} 
                />
                Eco
              </ToggleButton>
              <ToggleButton value="green">
                <FullGreenIcon 
                  sx={{ 
                    mr: 1,
                    color: formData.mode === 'green' ? 'primary.main' : 'inherit'
                  }} 
                />
                Full Green
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          <FormControl component="fieldset" margin="normal" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.autoStart}
                  onChange={handleInputChange}
                  name="autoStart"
                  disabled={formData.isPublic}
                />
              }
              label="Auto Start"
            />
            {formData.isPublic && (
              <FormHelperText>Auto start is only available for private chargepoints</FormHelperText>
            )}
          </FormControl>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">Authorized Users</Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <TextField
                label="Add user email"
                name="newUserEmail"
                value={formData.newUserEmail}
                onChange={handleInputChange}
                error={!!emailError}
                helperText={emailError}
                sx={{ flexGrow: 1 }}
              />
              <Button variant="contained" onClick={handleAddUser}>
                Add User
              </Button>
            </Box>
            <List>
              {formData.authorizedUsers.map((user) => (
                <ListItem key={user.email}>
                  <ListItemText primary={user.email} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => handleRemoveUser(user.email)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">Subscription Status</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Chip
                label={subscriptionStatus.active ? 'Active' : 'Inactive'}
                color={subscriptionStatus.active ? 'success' : 'default'}
              />
              {subscriptionStatus.active && (
                <Typography variant="body2">
                  Expires: {subscriptionStatus.expirationDate}
                </Typography>
              )}
            </Box>
          </Box>

          {chargerSupport !== 'unknown' && (
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle1">Eco charging:</Typography>
              {getSupportIcon()}
              <Typography variant="body1">
                {chargerSupport === 'yes' ? 'Supported' : 'Not Supported'}
              </Typography>
            </Box>
          )}

          <Box sx={{ mt: 4 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={!!passwordError}
            >
              Register Charge Point
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default RegisterChargePoint
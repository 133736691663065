import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { teal, orange } from '@mui/material/colors';

//import Loading from "./components/Loading";
//import Header from "./components/Header";
import Cards from "./views/Cards"
import Home from "./views/Home"
import Activity from "./views/Activity"
import Authorize from "./views/Authorize"
import Charge from "./views/Charge"
import ChargerList from "./views/ChargerList"
import Account from "./views/Account"
import ChargeWithBudget from "./views/ChargeWithBudget"
import RegisterChargePoint from "./views/RegisterChargePoint"
import { useAuth0 } from "@auth0/auth0-react"
import history from "./utils/history"
import UserHome from "./views/UserHome"
import StartCharging from "./views/StartCharging"

// styles
//import "./App.css";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "./output.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: orange,
  }
})

const App = () => {
  const { error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  // <Header />
  // <Route path="/cards" exact component={Cards} />
  // <Route path="/authorize/:card" component={Authorize} />
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={UserHome} />
          <Route path="/account" component={Account} />
          <Route path="/activity" component={Activity} />
          <Route path="/charge/:charger/:connector" component={Charge} />
          <Route path="/charge" component={ChargerList} />
          <Route path="/budget/:charger" component={ChargeWithBudget} />
          <Route path="/charger/register" component={RegisterChargePoint} />
          <Route path="/start" component={StartCharging} />
        </Switch>
      </ThemeProvider>
    </Router>
  );

  /*
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Cards} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
  */
};

export default App;
